<template>
  <div id="loginInput">
    <div class="bg_yzm">
      <h1 v-if="step == 1">请输入 {{ hidePhone(phone) }}收到的短信验证码</h1>
      <h1 v-else>请输入你需要接收的手机号码</h1>
    </div>

    <div class="loginInput flex-container" v-if="step != 1">
      <img class="icon" src="@image/me/login_tel.png" alt="" />
      <input
        maxlength="11"
        class="mima"
        type="text"
        v-model="bindPhone"
        placeholder="请输入手机号码"
      />
    </div>

    <div class="loginInput flex-container">
      <img class="icon" src="@image/me/login_sock.png" />
      <input
        class="flex1"
        maxlength="6"
        type="text"
        v-model="bindCode"
        placeholder="请输入验证码"
      />
      <!-- <img class='show' src="@image/me/login_show.png" > -->
      <!-- <div   class="solid"></div> -->
      <!-- <span>asdasd</span> -->
      <template>
        <div class="yzb_btn" @click="getCode()" v-if="time == 60">
          获取验证码
        </div>
        <div class="yzb_btn no_yzm_btn" v-else>{{ time }}s重新获取</div>
      </template>
    </div>

    <!-- yes_login -->
    <div class="next_btn" v-if="bindCode.length < 6">确认</div>
    <div
      class="next_btn yes_next"
      v-if="bindCode.length >= 6"
      @click="enterCode()"
    >
      确认
    </div>
    <div
      class="next_btn yes_next"
      style="margin-top: 0.5rem"
      v-if="step == 1 || propsStep == 2"
      @click="changeParents('cancel')"
    >
      我再想想
    </div>
  </div>
</template>
<script>
import { post } from "@get/http";
import updatePhone from "./updatePhone";
import { Toast } from "vant";

export default {
  components: {
    updatePhone,
  },
  props: {
    propsStep: {
      // 第几步骤  默认1
      default: 1,
      // type : String || Number,
      // required : true,
    },
    phone: {
      default: "",
      // type :String || Number,
    },
    onlyyz: {
      // 是否只需要验证
      default: false,
      type: Boolean,
    },
  },
  watch: {
    propsStep() {
      // 进来
      this.step = this.propsStep;
    },
  },
  data: () => ({
    time: 60,
    bindPhone: "",
    bindCode: "",
    step: 1,
    timeer: null,
  }),
  created() {
    Toast.clear();
    this.configOnload();
  },
  methods: {
    modeTransmit(type) {
      this.$emit("modeTransmit", type);
    },
    configOnload() {
      this.step = this.propsStep;
    },
    enterCode(type) {
      Toast.loading({
        message: "验证中...",
        loadingType: "spinner",
        overlay: true,
        forbidClick: true,
        duration: 0,
      });
      // 确定验证码
      let src = "/Memberaccount/check_auth_code";
      if (this.step == 2) {
        src = "/Memberaccount/bind_mobile_step2";
      }
      post(src, {
        data: {
          auth_code: this.bindCode,
        },
      });
      Toast("十九匠商城通知:验证成功");
      this.bindCode = "";
      clearInterval(this.timeer);
      this.time = 60;
      if (this.step == 2) {
        if (this.onlyyz) {
          this.changeParents("enterOnlyOk");
        } else {
          this.changeParents("enterPhoneYzm");
        }
      } else {
        if (this.onlyyz) {
          this.changeParents("enterOnlyOk");
        } else {
          this.step = 2;
          this.changeParents("enterYzm");
        }
      }
    },
    hidePhone(phone) {
      if (phone.length == 0) {
        return "";
      }
      // 隐藏手机号
      return phone.replace(/^(\d{3})\d{4}(\d+)/, "$1****$2");
    },
    async getCode() {
      // 获取验证码

      let FromConfig = {
        // 默认的发送短信配置  有绑定的情况下
        url: "/Memberaccount/send_auth_code",
        json: {
          type: "mobile",
        },
      };

      if (this?.step != 1) {
        // 注册的请求数据
        if (this.bindPhone.length < 11) {
          // 简单的判断一下
          Toast("十九匠商城通知:输入正确的手机号码");
          return false;
        }
        FromConfig.url = "/Memberaccount/bind_mobile_step1";
        FromConfig.json.mobile = this.bindPhone;
      }

      Toast.loading({
        message: "发送中...",
        loadingType: "spinner",
        overlay: true,
        forbidClick: true,
        duration: 0,
      });

      let data = await post(
        FromConfig.url,
        { data: FromConfig.json },
        { result: true, resultKey: "code" }
      );
      Toast("十九匠商城通知:发送成功");
      if (data.code == 10000) {
        this.time = data.result.sms_time;
        this.timeer = setInterval(() => {
          this.time--;
          if (this.time <= 0) {
            clearInterval(this.timeer);
            this.time = 60;
            this.changeParents("timeoutBtn");
          }
        }, 1000);
        this.changeParents("clickBtn");
      } else {
        Toast("十九匠商城通知:" + data.message);
        this.changeParents("errorBtn");
      }
    },
    changeParents(tips) {
      // 通知上级
      this.$emit("enterYzm", tips);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
#loginInput {
  min-height: 100%;
  padding: 0 rem(48);
  background: url("~@image/me/login_bg.png") no-repeat #fff;
  background-size: 100% rem(300);
}

.bg_yzm {
  h1 {
    padding: rem(110) 0 rem(60);
    font-size: rem(48);
    font-weight: bold;
    color: #333333;
  }
}

// #loginInput{
//   font-weight: 500;
//
// }

.loginInput {
  // padding: rem(60) 0;
  padding: rem(60) 0 rem(28) 0;
  align-items: center;
  border-bottom: rem(2) solid #f5f5f5;
  .icon,
  .show {
    width: rem(30);
    height: rem(30);
    display: block;
  }
  .show {
    margin-left: rem(10);
  }
  .solid {
    width: rem(2);
    height: rem(24);
    background: #ebebeb;
    margin: 0 rem(24);
  }
  input {
    font-size: rem(28);
    color: #808080;
  }

  span {
    font-size: rem(24);
    color: #808080;
  }
  input {
    border: none;
    width: rem(420);
    margin-left: rem(12);
  }
}

.yzb_btn {
  width: rem(180);
  height: rem(58);
  background: #ffebeb;
  border-radius: rem(29);
  text-align: center;
  line-height: rem(58);
  font-size: rem(24);

  color: #ff4747;
}
.no_yzm_btn {
  background: #f5f5f5;
  color: #b3b3b3;
}

.next_btn {
  font-size: rem(32);
  font-weight: bold;
  color: #ffffff;
  width: rem(640);
  height: rem(86);
  background: #e6e6e6;
  border-radius: rem(43);
  text-align: center;
  line-height: rem(86);
  margin: rem(86) auto 0;
}
.yes_next {
  background: #ff4747;
}

//
// .input-text{
//   height: rem(120);
//
//   border-bottom: rem(1) solid #ebebeb;
//   margin: 0 rem(32);
//   box-sizing: border-box;
//   align-items: center;
//   .van-cell{
//     padding: 0;
//   }
//   .van-cell__title{
//     width: rem(154);
//     font-size: rem(26);
//     font-weight: 500;
//     color: #7F7F7F;
//     margin: 0;
//     font-family: 'pf';
//     // margin-right: rem(56);
//   }
//   .van-field__control{
//     font-size: rem(28);
//     color: #000;
//     border:none;
//     font-family: 'pfb';
//   }
//   ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
//     color:    #B2B2B2;
//   }
//   :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
//      color:    #B2B2B2;
//   }
//   ::-moz-placeholder { /* Mozilla Firefox 19+ */
//      color:    #B2B2B2;
//   }
//   :-ms-input-placeholder { /* Internet Explorer 10-11 */
//      color:    #B2B2B2;
//   }
// }
</style>
