<template>
  <div id="">
    <div class="safe_infoLi flex-container">
      <b><slot /></b>
      <span class="flex1" @click="toPath()">{{ tips }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: ["tips"],
  name: "",
  data: () => ({}),
  methods: {
    toPath(path) {
      this.$emit("toPath", path);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
.safe_infoLi {
  background: url("~@image/me/icon_more.png") no-repeat center right;
  background-size: rem(12) rem(22);
  height: rem(118);
  align-items: center;
  justify-content: center;
  border-bottom: rem(1) solid #ebebeb;
  padding-right: rem(32);
  &:last-of-type {
    border-bottom: none;
  }
  b {
    font-size: rem(28);
    font-weight: bold;
    color: #333333;
  }
  span,
  b {
    line-height: rem(118);
    height: 100%;
  }
  span {
    font-size: rem(26);

    color: #808080;
    text-align: right;
  }
}
</style>
