<template>
  <div id="login">
    <div class="login">
      <h1>忘记密码</h1>
    </div>
    <div class="input_data">
      <template v-for="item in loginInput">
        <div class="loginInput flex-container">
          <img class="icon" :src="item.srcIcon" />
          <input
            class="flex1"
            :maxlength="item.len"
            :type="item.showPasswordicon ? 'text' : 'password'"
            name=""
            v-model="item.val"
            :placeholder="item.placeholder"
          />
          <img
            class="show"
            :src="
              item.showPasswordicon
                ? showPasswordIcon.show
                : showPasswordIcon.hide
            "
            @click="item.showPasswordicon = !item.showPasswordicon"
          />
        </div>
      </template>
    </div>
    <!-- yes_login -->
    <div
      class="login_btn"
      v-if="loginInput[0].val.length < 6 || loginInput[1].val.length < 6"
    >
      完成
    </div>
    <div class="login_btn yes_login" v-else @click="modifyFoo">完成</div>
  </div>
</template>
<script>
import { Toast, Field } from "vant";
import { getUpdateUserPassword, getUpdateUserPaypwd } from "../js/";

export default {
  name: "",
  data: () => ({
    showPasswordIcon: {
      hide: require("@image/me/login_show.png"),
      show: require("@image/me/login_sock.png"),
    },
    loginInput: [
      {
        srcIcon: require("@image/me/login_sock.png"),
        placeholder: "请输入新密码 (至少6位)",
        type: "text",
        len: "20",
        val: "",
        showPasswordicon: false,
      },
      {
        srcIcon: require("@image/me/login_sock.png"),
        placeholder: "请再次确认密码",
        type: "text",
        len: "20",
        val: "",
        showPasswordicon: false,
      },
    ],
  }),
  props: {
    path: {
      type: String,
      required: true,
    },
  },

  methods: {
    modifyFoo() {
      let types = this.path;
      if (types == "pay") {
        this.updatePaypwd();
      } else if ((types = "login")) {
        this.updatePassword();
      } else {
        Toast("十九匠商城:非法进入页面");
        this.$router.go(0);
      }
    },
    // 更改登录密码
    async updatePassword() {
      let that = this;
      await getUpdateUserPassword(
        this.loginInput[0].val,
        this.loginInput[1].val
      );
      that.$emit("enterYzm", "modifySuccess");
      this.onReload();
    },
    // 更改支付密码
    async updatePaypwd() {
      let that = this;
      await getUpdateUserPaypwd(this.loginInput[0].val, this.loginInput[1].val);
      that.$emit("enterYzm", "modifySuccess");
      this.onReload();
    },
    onReload() {
      this.loginInput[0].val = "";
      this.loginInput[1].val = "";
    },
  },

  components: {
    Field,
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
#login {
  height: 100%;
  padding: 0 rem(48);
  background: url("~@image/me/forget_bg.png") no-repeat #fff;
  background-size: 100% rem(300);
}
.login {
  h1 {
    padding: rem(110) 0 rem(60);
    font-size: rem(48);
    font-weight: bold;
    color: #333333;
  }
}

.login_btn {
  font-size: rem(32);
  font-weight: bold;
  color: #ffffff;
  width: rem(640);
  height: rem(86);
  background: #e6e6e6;
  border-radius: rem(43);
  text-align: center;
  line-height: rem(86);
  margin: rem(86) auto 0;
}
.yes_login {
  background: #ff4747;
}

.loginInput {
  // padding: rem(60) 0;
  padding: rem(60) 0 rem(28) 0;
  align-items: center;
  border-bottom: rem(2) solid #f5f5f5;
  .icon,
  .show {
    width: rem(30);
    height: rem(30);
    display: block;
  }
  .show {
    margin-left: rem(10);
  }
  .solid {
    width: rem(2);
    height: rem(24);
    background: #ebebeb;
    margin: 0 rem(24);
  }
  input {
    font-size: rem(28);
    color: #808080;
  }

  span {
    font-size: rem(24);
    color: #808080;
  }
  input {
    border: none;
    width: rem(420);
    margin-left: rem(12);
  }
}
</style>
