<template>
  <div id="safe">
    <!-- @click='toPath("New_MeInfo")' -->

    <div class="flex-container safe_meinfo">
      <img
        :src="user.member_avatar"
        alt=""
        v-if="user.member_avatar && user.member_avatar.length > 0"
      />
      <img src="@image/me/icon_tx.png" alt="" v-else />
      <div class="right flex1">
        <b>{{ user.member_nickname }}</b>
        <p>ID码: {{ user.member_id }}</p>
      </div>
    </div>

    <div class="safe_infoUl">
      <safe-list tips="" @toPath="toPath('Address')">地址管理</safe-list>
      <safe-list :tips="realState" @toPath="toPath('Real')" v-if="jiang_yc == 0"
        >实名认证</safe-list
      >

      <template v-if="jiang_yc == 0">
        <template
          v-if="
            user.member_mobile &&
              user.member_mobile.length > 0 &&
              user.member_mobilebind > 0
          "
        >
          <safe-list tips="去修改" @toPath="shwoPhonePopup(1, true, 'login')"
            >修改登录密码</safe-list
          >
          <safe-list tips="去修改" @toPath="shwoPhonePopup(1, true, 'pay')"
            >修改支付密码</safe-list
          >
          <safe-list
            :tips="hidePhone(user.member_mobile)"
            @toPath="shwoPhonePopup(1)"
            >更换手机号</safe-list
          >
        </template>
        <template v-else>
          <safe-list tips="去修改" @toPath="shwoPhonePopup(2, true, 'login')"
            >修改登录密码</safe-list
          >
          <safe-list tips="去修改" @toPath="shwoPhonePopup(2, true, 'pay')"
            >修改支付密码</safe-list
          >
          <safe-list @toPath="shwoPhonePopup(2)" tips="去绑定"
            >绑定手机号</safe-list
          >
        </template>
      </template>
    </div>
    <div class="safe_info_btn" @click="logout">退出当前账户</div>

    <van-popup
      v-model="thisPhoneBoo"
      round
      position="bottom"
      :overlay="false"
      :style="{ height: '98%' }"
    >
      <yzm
        :phone="user.member_mobile"
        :propsStep.sync="step"
        @enterYzm="enterYzm"
        :onlyyz="isOnlyYz"
      ></yzm>
    </van-popup>

    <van-popup
      v-model="thisPassword"
      round
      position="bottom"
      :overlay="false"
      :style="{ height: '98%' }"
    >
      <modifyPassword :path="path" @enterYzm="enterYzm"></modifyPassword>
    </van-popup>
  </div>
</template>
<script>
import safeList from "./components/list.vue";
import yzm from "./components/yzm.vue";
import modifyPassword from "./components/modifyPassword.vue";
import { post } from "@get/http";
import { getLogout, getMemberInfo } from "./js/";
import { Toast, Popup } from "vant";

export default {
  name: "",
  components: {
    safeList,
    vanPopup: Popup,
    yzm,
    modifyPassword,
  },
  data: () => ({
    isOnlyYz: false,
    jiang_yc: localStorage["19jiang_yc"] ? localStorage["19jiang_yc"] : 0,
    list: [
      {
        title: "地址管理",
        tips: "",
        click: "New_OrderAddress",
      },
      {
        title: "实名认证",
        tips: "未认证",
        click: "Real",
      },
      {
        title: "修改登录密码",
        tips: "130****000",
        click: "New_Forget",
      },
      {
        title: "更改手机号",
        tips: "130****000",
        click: "New_UpdatePhone",
      },
    ],
    realState: "",
    user: {},
    thisPhoneBoo: false,
    thisPassword: false,
    step: 1,
    path: "",
  }),
  computed: {},
  created() {
    Toast.clear();
    Toast.loading({
      message: "加载...",
      loadingType: "spinner",
      overlay: true,
      forbidClick: true,
      duration: 0,
    });
    this.getMemberInfo();
    Toast.clear();
  },

  methods: {
    modeTransmit(type) {},
    async getMemberInfo() {
      let { member_info } = await getMemberInfo();
      this.user = member_info;
      this.getRealState(member_info.member_auth_state);
      this.$store.dispatch("doUserInfo", member_info);
    },
    enterYzm(state) {
      if (state == "cancel") {
        this.thisPhoneBoo = false;
      }
      if (state == "enterPhoneYzm") {
        // 确定绑定手机
        this.thisPhoneBoo = false;
        this.step = 1;
        this.getMemberInfo();
      }
      if (state == "enterOnlyOk") {
        // 完成绑定
        this.thisPhoneBoo = false;
        this.step = 1;
        this.isOnlyYz = false;
        this.getMemberInfo();
        this.thisPassword = true;
      }
      if (state == "modifySuccess") {
        this.thisPassword = false;
      }
    },
    hidePhone(phone) {
      if (phone.length == 0) {
        return "";
      }
      // 隐藏手机号
      return phone.replace(/^(\d{3})\d{4}(\d+)/, "$1****$2");
    },

    toPath(path) {
      this.$router.push({
        name: path,
      });
    },

    shwoPhonePopup(step, mode, path) {
      if (mode) {
        this.isOnlyYz = true;
        this.path = path;
      }
      this.step = step;
      this.thisPhoneBoo = true;
    },

    logout() {
      getLogout(this.user.member_name)
        .then((res) => {
          this.$store.dispatch("doUserClear", "clear");
          sessionStorage.removeItem("RENINE_SHOP_I_ID");
          this.$router.push({ name: "HomeIndex" });
          Toast("十九匠商城通知:已登出");
        })
        .catch(function(error) {
          Toast(error.message);
        });
    },

    getRealState(state) {
      if (state == 0) {
        this.realState = "未实名";
      } else if (state == 1) {
        this.realState = "审核中";
      } else if (state == 2) {
        this.realState = "未通过";
      } else if (state == 3) {
        this.realState = "已实名";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
#safe {
  height: 100%;
  background: #ededed;
  padding-top: rem(16);
}
.safe_meinfo {
  background: #fff;
  padding: rem(40) rem(32);
  align-items: center;
  margin-bottom: rem(16);
  border-radius: rem(40) rem(40) 0px 0px;

  img {
    width: rem(144);
    height: rem(144);
    border-radius: 50%;
  }
  .right {
    margin-left: rem(20);
    background: url("~@image/me/icon_more.png") no-repeat center right;
    background-size: rem(12) rem(22);
    b {
      font-size: rem(36);
      font-weight: bold;
      color: #333333;
    }
    p {
      font-size: rem(26);
      color: #808080;
    }
  }
}

.safe_infoUl {
  background: #fff;
  margin-bottom: rem(16);
  padding: 0 rem(32);
}

.safe_info_btn {
  width: 100%;
  height: rem(120);
  background: #ffffff;
  font-size: rem(28);
  font-weight: bold;
  color: #ff4848;
  line-height: rem(120);
  text-align: center;
}
</style>
