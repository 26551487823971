<template lang="html">
  <div class="">
    <div class="bg_yzm">
      <h1>请输入你需要更改的手机号码</h1>
    </div>

    <div class="loginInput flex-container">
      <img class="icon" src="@image/me/login_tel.png" alt="" />
      <input
        maxlength="11"
        class="mima"
        type="text"
        v-model="bindPhone"
        placeholder="请输入手机号码"
      />
    </div>

    <div class="loginInput flex-container">
      <img class="icon" src="@image/me/login_sock.png" />
      <input
        class="flex1"
        maxlength="6"
        type="text"
        v-model="bindCode"
        placeholder="请输入验证码"
      />
      <!-- <img class='show' src="@image/me/login_show.png" > -->
      <!-- <div   class="solid"></div> -->
      <!-- <span>asdasd</span> -->
      <template>
        <div class="yzb_btn" @click="getCode()" v-if="time == 60">
          获取验证码
        </div>
        <div class="yzb_btn no_yzm_btn" v-else>{{ time }}s重新获取</div>
      </template>
    </div>

    <div class="next_btn" v-if="bindCode.length < 6">确认</div>
    <div
      class="next_btn yes_next"
      v-if="bindCode.length >= 6"
      @click="enterCode()"
    >
      确认
    </div>
    <div
      class="next_btn yes_next"
      style="margin-top: 0.5rem"
      @click="changeParents('cancel')"
      v-if="isyz"
    >
      我再想想
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  getUpdateUserMobile,
  getSendAuthCode,
  getBindUserMobile,
} from "../js/";
export default {
  data() {
    return {
      time: 60,
      bindPhone: "",
      bindCode: "",
      timeer: null,
    };
  },
  props: {
    isyz: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async enterCode(type) {
      this.changeParents("final");

      Toast.loading({
        message: "验证中...",
        loadingType: "spinner",
        overlay: true,
        forbidClick: true,
        duration: 0,
      });
      await getUpdateUserMobile(this.bindCode);

      Toast("十九匠商城通知:验证成功");
      this.bindCode = "";
      clearInterval(this.timeer);
      this.time = 60;
      if (!next) {
        this.changeParents("noNext");
      }
    },
    async getCode() {
      // 获取验证码

      let FromConfig = {
        // 默认的发送短信配置  有绑定的情况下
        url: "/Memberaccount/send_auth_code",
        json: {
          type: "mobile",
        },
      };

      if (this.bindPhone.length < 11) {
        // 简单的判断一下
        Toast("十九匠商城通知:输入正确的手机号码");
        return false;
      }

      Toast.loading({
        message: "发送中...",
        loadingType: "spinner",
        overlay: true,
        forbidClick: true,
        duration: 0,
      });

      let data = await getBindUserMobile();
      Toast("十九匠商城通知:发送成功");
      if (data.code == 10000) {
        this.time = data.result.sms_time;
        this.timeer = setInterval(() => {
          this.time--;
          if (this.time <= 0) {
            clearInterval(this.timeer);
            this.time = 60;
            this.changeParents("timeoutBtn");
          }
        }, 1000);
        this.changeParents("clickBtn");
      } else {
        Toast("十九匠商城通知:" + data.message);
        this.changeParents("errorBtn");
      }
    },
    changeParents(tips) {
      // 通知上级

      this.$emit("enterYzm", tips);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.bg_yzm {
  h1 {
    padding: rem(110) 0 rem(60);
    font-size: rem(48);
    font-weight: bold;
    color: #333333;
  }
}

.next_btn {
  font-size: rem(32);
  font-weight: bold;
  color: #ffffff;
  width: rem(640);
  height: rem(86);
  background: #e6e6e6;
  border-radius: rem(43);
  text-align: center;
  line-height: rem(86);
  margin: rem(86) auto 0;
}
.yes_next {
  background: #ff4747;
}
</style>
